// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: 'Bärenkinder App - Testautomation',
  production: false,
  version: require('../../package.json').version,
  buildNumber: require('../../package.json').buildNumber,
  apiUrl: 'https://testautomation.api.baerenkinder.neobitsoft.de/',
  client_id: 2,
  client_secret: 'LQHX108ivI2iokyj5tJzCVJseLWeO7xC3miQcBfw',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
